.mycard.horizontal {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.mycard .card-image {
  position: relative;
}

.mycard.horizontal .card-stacked {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
}
.mycard .card-content {
  padding: 15px 10px 0 0;
  border-radius: 0 0 2px 2px;
  flex-grow: 1;
}

.mycard.horizontal .card-image img {
  width: 150px;
  height: 150px;
  margin-right: 20px;
  border-radius: 5px;
}

.my-card {
  position: relative;
  margin: 2% 5px 2% 5px;
  background-color: #fff;
  transition: box-shadow .25s;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  padding: 0;
  min-width: 400px;
  max-width: 500px;
}