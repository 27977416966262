.onHoverBtn:hover {
  color:rgb(255, 255, 255)!important;
  background-color: rgb(19, 138, 243);
  border-color: white;
  transform: scale(1.1, 1.1);
  transition: all ease-in-out .3s;
}

.onHoverBtn{
  transition: all ease-in-out .3s;
  border-color: rgb(19, 138, 243);
}

.iconColor{
  color: rgb(19, 138, 243);
}
.onHoverBtn:hover .iconColor{
  color: white
}